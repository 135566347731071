// @flow
import mitt from 'mitt';
import type { Emitter } from 'mitt';
import { SliceRepresentation } from 'react-vtk-js';
import type { ViewContext } from 'react-vtk-js';
import typeof { SlicingMode } from '@kitware/vtk.js';
import type { $AxisDirection, IRet } from '@kitware/vtk.js';
import { add, clampValue, multiply3x3_vect3 } from '@kitware/vtk.js/Common/Core/Math';
import type { vec3 } from '@kitware/vtk.js/Common/Core/Math';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import vtkPlane from '@kitware/vtk.js/Common/DataModel/Plane';
import type { SetStateAction } from 'types';
import { nanoid } from 'nanoid';

import type { Study, ViewportsConfigurations } from 'domains/viewer/ViewportsConfigurations/types';
import {
  IMAGE_NORMALS,
  DRE_2D_VIEWPORT_TYPES,
  DRE_MPR_VIEWPORT_TYPES,
  DRE_3D_VIEWPORT_TYPES,
} from 'config/constants';
import type { DreMprViewportTypes, DreAllViewportTypes } from 'config/constants';
import {
  worldToIndex as tagsWorldToIndex,
  indexToWorld as tagsIndexToWorld,
} from 'utils/dicomTagUtils';

import {
  AUTOMATIC_LINKED_SCROLL_TOLERANCE_RADIANS,
  BLANK_SLICE,
  SLICE_AXIS,
  SLICE_AXIS_INDEX,
} from '../../constants';
import {
  applyTransformMatrix,
  areNormalsParallelWithTolerance,
  check2DLineIntersection,
  invertTransformMatrix,
  make3x3MatrixFromDirection,
} from '../../utils/math';

import type LinkManager from './LinkManager';
import type { PositionToSliceTransform } from './LinkManager';
import { getLPSDirections } from './utils';
import type { LPSDirections } from './utils';

import type { TypedArray } from 'modules/viewer/imaging/types';
import type { RenderManager } from './RenderManager';
import type { SynchronizationBroadcaster } from './SynchronizationBroadcaster';
import type { TScrollLinkMode } from '../state';
import { LOADING_PRIORITIES } from 'domains/viewer/loadingPriorities';
import type { SupportedTextureTypes, SupportedTexturesMap } from 'utils/textureUtils';

import { ViewTypeValues } from 'generated/graphql';
import type { LoadType } from '../../../Viewer/ViewerMemoryManager';
import type { Region, FrameDataFragment, ImageParams } from 'generated/graphql';
import type {
  FullBaseStack,
  FullSingleLayerStack,
  ImageRegistration,
  Series,
  Stack,
} from '../../../ViewportsConfigurations/types';
import type { TransformMatrix } from '../../utils/math';
import { logger } from 'modules/logger';

/**
 * Events available to the imaging provider event emitter.
 */
export type EmitterEvent =
  | 'framesLoaded'
  | 'seriesLoaded'
  | 'stackLoaded'
  | 'loadError'
  | 'statusUpdated'
  | 'volumeUpdated'
  | string;

export const emitterEvents: { [key: string]: EmitterEvent } = {
  /**
   * Invoked every time new instances (slices / frames) are loaded.
   * Includes a single parameter indicating the total number of
   * frames that have currently been loaded.
   */
  framesLoaded: 'framesLoaded',
  /**
   * Invoked when all image data for the series has been loaded.
   */
  stackLoaded: 'stackLoaded',
  loadError: 'loadError',
  statusUpdated: 'statusUpdated',
  volumeUpdated: 'volumeUpdated',
};

export const createActiveSliceChangedEmitterEvent = (viewType: DreAllViewportTypes): EmitterEvent =>
  `${viewType}:activeSliceChanged`;

export function getTypedArrayName(arr: TypedArray): string {
  return arr.constructor.name;
}

export type SlicePlane = { slicePosition: vec3, sliceNormal: vec3 };
export type ProviderStatus = 'init' | 'loading' | 'complete' | 'error';
export type BaseImagingProviderArgs<+T: $ReadOnly<{ ...FullBaseStack, ... }>> = {
  study: Study,
  +stack: T,
  series: ?Series,
  linkManager: LinkManager,
  singleSortedSeries: boolean,
  renderManager: RenderManager,
  synchronizationBroadcaster: SynchronizationBroadcaster,
  frameOfReferenceUID: ?string,
  imageRegistrations: $ReadOnlyArray<ImageRegistration>,
  supportedTextures: SupportedTexturesMap,
};

type ViewportState = {
  viewportId: string,
  viewType: DreAllViewportTypes,
  /*
   * Index of the slice of this series to display to the user for a given view type
   */
  activeSlice: number,
  manualSyncPosition: ?vec3,
  slicingModeIndex: ?$Values<SlicingMode>,
  vtkSlicers: (typeof SliceRepresentation)[],
};

type ViewportsStateMap = Map<string, ViewportState>;

const DEFAULT_VIEWTYPE_STATE: $Diff<
  ViewportState,
  { viewportId: mixed, viewType: mixed, activeSlice: mixed },
> = {
  manualSyncPosition: null,
  slicingModeIndex: null,
  vtkSlicers: [],
};
const DEFAULT_VIEWPORT_STATE: $Diff<ViewportState, { viewportId: mixed, viewType: mixed }> = {
  ...DEFAULT_VIEWTYPE_STATE,
  activeSlice: 0,
};

/**
 * Base class for providing imaging data for a stack of pixel frames.
 *
 * Since JavaScript does not have interfaces or abstract classes,
 * we are using a regular JavaScript class and denoting protected
 * methods with an underscore prefix.
 *
 * This class should not be directly instantiated, as it only contains
 * shared logic for fetching image data; logic for how to fetch image
 * data for a stack resides in a provider class that extends `BaseImagingProvider`.
 */
export class BaseImagingProvider<+T: Stack> {
  /* Study containing this stack */
  study: Study;
  /* Stack of frames containing the images */
  +stack: T;
  /* TODO */
  #series: ?Series;
  singleSortedSeries: boolean;
  /* VTK Image source for this stack
   * this will always be a 3dable axis aligned volume that we will use for display purposes
   * if you need an imageData for a particular slice on a non3dable series, use the useActiveSliceImage hook
   * or the getImage property
   */
  #vtkImage: typeof vtkImageData;
  /* A string propery to identify the highest linked abstraction ID*/
  linkSmid: string;
  /* Current status of the imaging provider */
  status: ProviderStatus;
  /* An early error held to throw once loading is attempted */
  deferredError: string | null;
  /* Indicates if the image data was pulled from cache or over the network */
  fromCache: boolean;
  /* Indicates the type of the provider used*/
  type: string;
  /**
   * Event emitter to which consumers can subscribe to imaging provider updates.
   * Available events are listed in the {emitterEvents} constant.
   */
  emitter: Emitter<EmitterEvent, mixed>;
  #frameOfReferenceUID: ?string;
  /**
   * Reference to the current VTKJS `View` object
   */
  viewRef: { current: ?ViewContext };
  lpsDirections: LPSDirections;

  // untar method used
  unpackingMethod: 'tardah';

  /* Stores the in flight `load` requests in case multiple parallel calls to `load` are made */
  deferredLoad: ?Promise<void>;

  /* Stores state that is dependent on view type and viewport id */
  #viewportsStateMap: ViewportsStateMap = new Map();

  /* Quick lookup of available view types once calculated in {getAvailableViewTypes} */
  #availableViewTypes: Array<DreAllViewportTypes>;

  /**
   * Manages links between stacks (for automatic / manual scroll, etc).
   */
  #linkManager: LinkManager;

  synchronizationBroadcaster: SynchronizationBroadcaster;

  #renderManager: RenderManager;

  /** Signals if this provider was in one of the initially loaded viewports */
  #partOfInitialLoad: boolean;

  /** Signals if this provider was drag and drop */
  #isDroppedStack: boolean;

  loadAttempts: number = 0;

  // controls whether images should be loaded into memory
  loadType: LoadType;

  #imageChangeListeners: Map<
    string,
    {
      viewType: DreAllViewportTypes,
      viewportId: string,
      callback: (vtkImage: typeof vtkImageData | null) => void,
    },
  >;

  imageRegistrations: $ReadOnlyArray<ImageRegistration>;
  supportedTextures: SupportedTexturesMap;
  absoluteScroll: boolean = true;

  constructor({
    study,
    stack,
    series,
    frameOfReferenceUID,
    linkManager,
    synchronizationBroadcaster,
    singleSortedSeries = false,
    renderManager,
    imageRegistrations,
    supportedTextures,
  }: BaseImagingProviderArgs<T>) {
    this.emitter = mitt();
    this.study = study;
    this.stack = stack;
    this.linkSmid = stack.smid;
    this.singleSortedSeries = singleSortedSeries;
    this.fromCache = false;
    this.type = 'default';
    this.status = 'init';
    this.viewRef = { current: null };
    this.#linkManager = linkManager;
    this.imageRegistrations = imageRegistrations;
    this.synchronizationBroadcaster = synchronizationBroadcaster;
    this.#renderManager = renderManager;
    this.#availableViewTypes = [];
    this.#isDroppedStack = false;

    this.unpackingMethod = 'tardah';
    this.loadType = 'full';
    this.#imageChangeListeners = new Map();
    this.#frameOfReferenceUID = frameOfReferenceUID;
    this.#series = series;
    this.supportedTextures = supportedTextures;
    this.setupStack();
  }

  /******************************************************************
   * Public API
   ******************************************************************/

  //eslint-disable-next-line no-use-before-define
  getLayerProvider(layerId: number): BaseImagingProvider<FullSingleLayerStack> {
    throw new Error('Not implemented');
  }

  isSopClass(classes: string): boolean {
    throw new Error('Not implemented');
  }

  hasValidDirection(): boolean {
    throw new Error('Not implemented');
  }

  setupStack() {
    const { direction } = this.imageParams;
    const lpsdirections = getLPSDirections(direction);
    const mockImage = generateMockVtkImage(this.imageParams);

    this.#vtkImage = mockImage;
    this.lpsDirections = lpsdirections;
  }

  updateAbsoluteScrollPreference(absoluteScroll: boolean) {
    this.absoluteScroll = absoluteScroll;
  }

  // $FlowIgnore[unsafe-getters-setters]
  get vtkImage(): typeof vtkImageData {
    return this.#vtkImage;
  }

  /* Map of the frame order in the series to the frame SMID */
  // $FlowIgnore[unsafe-getters-setters]
  get frameSmidsMap(): { [key: number]: string } {
    throw new Error('Not implemented');
  }
  /* Number of slices / frames in the stack */
  // $FlowIgnore[unsafe-getters-setters]
  get stackSize(): number {
    throw new Error('Not implemented');
  }

  /* DICOM instance tags for this stack */
  // $FlowIgnore[unsafe-getters-setters]
  get frameTags(): $ReadOnlyArray<FrameDataFragment> {
    throw new Error('Not implemented');
  }

  /**
   * General parameters for the image used in calculating transforms, positioning, etc.
   */
  // $FlowIgnore[unsafe-getters-setters]
  get imageParams(): ImageParams {
    throw new Error('Not implemented');
  }

  getFrameAxes(): Set<$AxisDirection> {
    throw new Error('Not implemented');
  }

  hasPixels(): boolean {
    throw new Error('Not implemented');
  }

  canRender(renderEngine: string): boolean {
    throw new Error('Not implemented');
  }

  is3Dable(): boolean {
    throw new Error('Not implemented');
  }

  estimateMemory(): number {
    throw new Error('Not implemented');
  }

  estimatePerFrameMemory(): number {
    throw new Error('Not implemented');
  }

  /**
   * Implemented in extending classes.
   *
   * Returns the underlying TypedArray that contains the pixel data for this series.
   */
  getVolume(): SupportedTextureTypes {
    throw new Error('Not implemented.');
  }

  /**
   * Implemented in extending classes.
   *
   * Returns the min / max pixel values for the underlying dataset.
   */
  getRange(): [number, number] | null {
    throw new Error('Not implemented.');
  }

  /**
   * Boolean value that is `true` when we have enough data to render and image via VTK,
   * either partial image data (for progressive fetching) or all image data for the series.
   */
  isReadyToRender(): boolean {
    throw new Error('Not implemented.');
  }

  wasPartOfInitialLoad(): boolean {
    return this.#partOfInitialLoad;
  }

  setWasPartOfInitialLoad(initialLoad: boolean) {
    this.#partOfInitialLoad = initialLoad;
  }

  isDroppedStack(): boolean {
    return this.#isDroppedStack;
  }

  isLoading(): boolean {
    return this.status === 'loading';
  }

  /**
   * Boolean value indicating if this series contains images acquired along
   * at least 2 distinct axes (Coronal, Axial, Sagittal).
   */
  isMultiAxesListOfFrames(): boolean {
    return this.imageParams.isMultiAxes;
  }

  /**
   * Boolean value that is `true` when we have an active reference to the VTK `View`.
   * Indicates if this series is currently being displayed in a viewport.
   */
  hasVtkView(): boolean {
    return this.viewRef.current != null;
  }

  /**
   * Load the image data for this series, if not already loaded.
   * If the load is already in progress, waits for its completion.
   */
  async load(
    {
      initialSlice = 0,
      priority,
    }: {
      initialSlice?: number,
      priority: number,
    } = { priority: 0 }
  ): Promise<mixed> {
    if (this.deferredLoad) {
      this.updateLoadPriority({ focus: initialSlice, priority });
      await this.deferredLoad;
    } else if (this.status === 'init' || (this.status === 'error' && this.deferredError == null)) {
      this.setStatus('loading');

      try {
        this.deferredLoad = this._loadStack(initialSlice, priority);
        await this.deferredLoad;
      } catch (e) {
        this.setStatus('error');
        throw e;
      }
    } else if (this.status === 'error' && this.deferredError != null) {
      this.emitter.emit(emitterEvents.loadError, this.deferredError);
      this.deferredError = null;
      try {
        // begin loading in the background to put data at least in the cache for later
        this.deferredLoad = this._loadStack(initialSlice, priority);
        await this.deferredLoad;
      } catch (e) {
        this.setStatus('error');
        throw e;
      }
    }
  }

  /**
   * Cancels an inflight load.
   *
   */
  cancelLoad(): void {
    throw new Error('Not implemented.');
  }

  async waitForLoadFinished(): Promise<void> {
    await this.deferredLoad;
  }

  failLoading(error: string, deferredThrow?: boolean = false) {
    this.deferredLoad = null;
    if (this.status === 'init' && deferredThrow === true) {
      // sometimes we should force the imaging provider to fail as soon it starts loading
      // we want to keep loading so it will download images to the cache
      // but the viewport should be in an error state
      // (current use case- Not Enough Memory to render full stack)
      this.deferredError = error;
    }
    this.setStatus('error');
    this.emitter.emit(emitterEvents.loadError, error);
  }

  setStatus(status: ProviderStatus) {
    this.status = status;
    this.emitter.emit(emitterEvents.statusUpdated, status);
  }

  /**
   * Transforms a point in world space (xyz) to index space (ijk).
   */
  worldToIndex(position: vec3, tags?: ?FrameDataFragment): vec3 {
    if (this.is3Dable() && this.vtkImage != null) {
      return this.vtkImage.worldToIndex(position);
    } else if (tags != null) {
      return tagsWorldToIndex(tags)(position);
    }

    // $FlowIgnore[incompatible-call] - Only `data` is needed for the direction tag
    return tagsWorldToIndex({
      origin: this.imageParams.origin,
      spacing: this.imageParams.spacing,
      direction: { data: this.imageParams.direction },
    })(position);
  }

  /**
   * Transforms a point in index space (ijk) to world space (xyz).
   */
  indexToWorld(position: vec3, tags?: ?FrameDataFragment): vec3 {
    if (this.is3Dable() && this.vtkImage) {
      return this.vtkImage.indexToWorld(position);
    } else if (tags != null) {
      return tagsIndexToWorld(tags)(position);
    }

    // $FlowIgnore[incompatible-call] - Only `data` is needed for the direction tag
    return tagsIndexToWorld({
      origin: this.imageParams.origin,
      spacing: this.imageParams.spacing,
      direction: { data: this.imageParams.direction },
    })(position);
  }

  /**
   * Returns the DICOM instance tags for the frame associated with the given {viewIndex},
   * taking into account the given {viewType}.
   *
   * For the acquisition view, {viewIndex} will always be positive as the min/max
   * values for slice positions are always positive and based on the number of frames
   * in the series (0..numFrames).
   *
   * For MPR views, {viewIndex} could be negative as it is not based on number of frames.
   * In this case, we use the tags for the first frame in the series.
   */
  getFrameTagsForViewIndex(viewType: DreAllViewportTypes, position: number): ?FrameDataFragment {
    const targetPosition = position >= 0 && viewType === 'TWO_D_DRE' ? position : 0;
    return this.frameTags[targetPosition];
  }

  /**
   * Return true if there is valid spacing and either no calibration region or a single
   * one.
   */
  hasContiguousSpacing(viewType: DreAllViewportTypes, position: number): boolean {
    const tags = this.getFrameTagsForViewIndex(viewType, position);

    const validSpacing = tags?.validSpacing ?? false;
    // GraphQL returns [1, 1, 1] for spacing for all US, with valid spacing tag. This would
    // display a ruler that does not match. Because all region calculation goes through
    // calibrated length the spacing was inconsistent between updates to annotations (world coords)
    const hasRegions = (this.getCalibratedRegions(viewType, position)?.length ?? 0) > 0;

    return validSpacing && !hasRegions;
  }

  /**
   * Returns the calibrated regions we support within the RCS module or null if they do not exist.
   */
  getCalibratedRegions(
    viewType: DreAllViewportTypes,
    position: number
  ): $ReadOnlyArray<Region> | null {
    const tags = this.getFrameTagsForViewIndex(viewType, position);

    return tags?.modules.regionCalibration?.regions ?? null;
  }

  /**
   * Returns the position vector on which manual scroll sync is based for the given {viewType}.
   * Used to determine the distance between the sync position and the current position, which
   * can then be used to determine the new slice of manually linked series.
   */
  getManualSyncPosition(viewType: DreAllViewportTypes, viewportId: string): ?vec3 {
    return this.#getViewportState(viewType, viewportId).manualSyncPosition;
  }

  /**
   * Return the current active slice for the given view of this series.
   * If no viewportId is provided, it will search the state map for one with that viewType
   */
  getActiveSlice(viewType: DreAllViewportTypes, viewportId?: string): number {
    if (viewportId != null) {
      return this.#getViewportState(viewType, viewportId).activeSlice;
    }

    return (
      Array.from(this.#viewportsStateMap.entries()).find(
        ([stateKey, viewportState]) =>
          this.#deserializeViewportStateKey(stateKey).viewType === viewType
      )?.[1].activeSlice ?? 0
    );
  }

  /**
   * Get the slice plane for the currently active slice of the given {viewType}.
   */
  getActiveSlicePlane(viewType: DreAllViewportTypes, viewportId: string): ?SlicePlane {
    const activeSlice = this.getActiveSlice(viewType, viewportId);
    return this.getSlicePlaneAtViewIndex(viewType, activeSlice);
  }

  /**
   * Returns an object containing two vectors (normal and position vectors) for
   * the frame at the given {viewIndex} if the {viewIndex} is valid.
   *
   * Returns `null` for an invalid {viewIndex}
   */
  getSlicePlaneAtViewIndex(
    viewType: DreAllViewportTypes,
    viewIndex: number,
    layerIndex?: number = 0
  ): ?SlicePlane {
    const viewTypeState = this.#getViewTypeState(viewType);
    const tags = this.getFrameTagsForViewIndex(viewType, viewIndex);
    const viewTypeNormal = IMAGE_NORMALS[viewType];
    if (tags == null || viewTypeState == null || viewTypeNormal == null) return;

    const slicer = this.getSlicerForViewtype(viewType, 0);
    const direction3x3Matrix = make3x3MatrixFromDirection(tags.direction.data);
    const sliceNormal = [...viewTypeNormal];
    let slicePosition: vec3 = [0, 0, 0];
    if (viewType === 'TWO_D_DRE' && tags.origin) {
      // if we are in 2D, we can use the slice's origin
      // or grab the top left corner with [0,0,slice]
      // since we are in image space, we know that position[2] should be the
      // value of the current slice
      // we multiply the normal by the direction to get the world space offset
      slicePosition = [tags.origin[0], tags.origin[1], tags.origin[2]];
      multiply3x3_vect3(direction3x3Matrix, sliceNormal, sliceNormal);
    } else {
      const mapper = slicer?.getMapper();
      // if we are in an MPR viewport, we attempt to grab the positon from the slicer's mapper
      // as it contains the most accurate information about slices
      // otherwise we do a small hack by moving from the origin along the slicing axis
      if (mapper != null) {
        const bounds = mapper.getBoundsForSlice(viewIndex) ?? [0, 0, 0, 0, 0, 0, 0];
        const topLeft: vec3 = this.worldToIndex(
          bounds != null ? [bounds[0], bounds[2], bounds[4]] : [0, 0, 0]
        );
        slicePosition = this.indexToWorld(topLeft);
      } else if (tags.origin) {
        const slicingModeIndex = viewTypeState.slicingModeIndex ?? 2;
        slicePosition = [tags.origin[0], tags.origin[1], tags.origin[2]];

        slicePosition[slicingModeIndex] = viewIndex;
      }
    }

    return { sliceNormal, slicePosition };
  }
  /**
   * Updates the active slice for the given view of this series, which includes:
   * - Updating private class properties to reflect the updated slice
   * - Update the active slice of any view on another series linked to the given view of this series
   * - Queue emitting slice changes to listeners on this provider and any linked providers, such as useActiveSlice hooks
   * - Queue broadcasting all affected series' slice changes to other windows
   * - Queue that this provider wants a rerender (which may not happen if there is no VTK view attached)
   * - Inside throttled callbacks, these three queues then performantly empty and dispatch their actions in batches
   */
  setActiveSlice(viewType: DreAllViewportTypes, viewportId: string, slice: number) {
    const slicePlane = this.getSlicePlaneAtViewIndex(viewType, slice);
    const manualScrollDistance = this._calculateManualScrollDistance(viewType, viewportId);
    if (slicePlane != null || slice === BLANK_SLICE) {
      this._updateSliceAndLinks(
        viewType,
        viewportId,
        slice,
        slicePlane?.slicePosition,
        manualScrollDistance
      );
    }
  }

  /**
   * Sets the {manualSyncPosition} for the given {viewType} to the position
   * of the active slice.
   */
  snapshotManualSyncPosition() {
    const { viewports } = Array.from(this.#viewportsStateMap.keys()).reduce<{
      viewports: Array<string>,
      map: { [string]: boolean },
    }>(
      (acc, viewStateKey: string) => {
        const { viewportId } = this.#deserializeViewportStateKey(viewStateKey);

        if (!acc.map[viewportId]) {
          acc.viewports.push(viewportId);
          acc.map[viewportId] = true;
        }

        return acc;
      },
      { viewports: [], map: {} }
    );

    this.getAvailableViewTypes().forEach((viewType) => {
      viewports.forEach((viewportId) => {
        const activeSlicePlane = this.getActiveSlicePlane(viewType, viewportId);

        if (activeSlicePlane == null) return;

        this.#setViewportState(viewType, viewportId, (state) => ({
          ...state,
          manualSyncPosition: activeSlicePlane.slicePosition,
        }));
      });
    });
  }

  /**
   * Sets the {manualSyncPosition} for the given {viewType} to the position
   * of the active slice.
   */
  clearManualSyncPosition() {
    const { viewports } = Array.from(this.#viewportsStateMap.keys()).reduce<{
      viewports: Array<string>,
      map: { [string]: boolean },
    }>(
      (acc, viewStateKey: string) => {
        const { viewportId } = this.#deserializeViewportStateKey(viewStateKey);

        if (!acc.map[viewportId]) {
          acc.viewports.push(viewportId);
          acc.map[viewportId] = true;
        }

        return acc;
      },
      { viewports: [], map: {} }
    );

    this.getAvailableViewTypes().forEach((viewType) => {
      viewports.forEach((viewportId) => {
        this.#setViewportState(viewType, viewportId, (state) => ({
          ...state,
          manualSyncPosition: null,
        }));
      });
    });
  }

  // Taken from https://github.com/Kitware/vtk-js/blob/ffdb65acbc9ebad60f0299d41025da5a86f8bb88/Sources/Rendering/Core/ImageMapper/index.js#L157-L216
  // Combined with using the LPS directions to get the XYZ axis based on desired view type,
  // we don't need the slicer's image mapper to compute the closest IJK axis.
  getIJKSlicingModeIndex(viewType: DreAllViewportTypes, position: number): number {
    if (viewType === 'TWO_D_DRE') {
      // Default acquisition view is always in K slicing mode
      return 2;
    }

    const xyzSlicingMode = this.getXYZSlicingModeIndex(viewType);
    const direction = this.imageParams.direction;
    let inVec3;
    switch (xyzSlicingMode) {
      case 0:
        // X axis slicing mode
        inVec3 = [1, 0, 0];
        break;
      case 1:
        // Y axis slicing mode
        inVec3 = [0, 1, 0];
        break;
      case 2:
        // Z axis slicing mode
        inVec3 = [0, 0, 1];
        break;
      default:
        return xyzSlicingMode;
    }

    // Project vec3 onto direction cosines
    const out = [0, 0, 0];
    multiply3x3_vect3(direction, inVec3, out);

    let maxAbs = 0.0;
    let ijkMode = -1;
    for (let axis = 0; axis < out.length; ++axis) {
      const absValue = Math.abs(out[axis]);
      if (absValue > maxAbs) {
        maxAbs = absValue;
        ijkMode = axis;
      }
    }

    return ijkMode;
  }

  /**
   * Gets the XYZ slicing mode index (0 -> X, 1 -> Y, 2 -> Z) for the given view of this series,
   * representing the axis along which we move when scrolling with the given view.
   *
   * Not to be used with the acquisition view, which always slices along the K axis in IJK mode;
   * returns -1 for the acquisition view.
   */
  getXYZSlicingModeIndex(viewType: DreAllViewportTypes): number {
    let slicingModeAxis = 0;
    switch (viewType) {
      case 'CORONAL_DRE':
        slicingModeAxis = SLICE_AXIS_INDEX.CORONAL_DRE;
        break;
      case 'AXIAL_DRE':
        slicingModeAxis = SLICE_AXIS_INDEX.AXIAL_DRE;
        break;
      case 'SAGITTAL_DRE':
        slicingModeAxis = SLICE_AXIS_INDEX.SAGITTAL_DRE;
        break;
      case 'TWO_D_DRE':
        slicingModeAxis = -1;
        break;
      default:
        slicingModeAxis = 0;
    }

    return slicingModeAxis;
  }

  /**
   * Returns a list of available 2D view types for this series.
   * Dynamically calculating which of the three MPR view types
   * (axial, coronal, or sagittal) are available for display
   * to the user by filtering out the MPR view that is parallel to
   * the acquisition view.
   *
   * Example: For a chest CT that displays on the axial plane by default
   *          (the acquisition view), the view types returned will be:
   *          ['TWO_D_DRE', 'CORONAL_DRE', 'SAGITTAL_DRE']
   */
  getAvailableViewTypes(): Array<DreAllViewportTypes> {
    if (this.#availableViewTypes.length === 0) {
      if (!this.is3Dable()) {
        this.#availableViewTypes = DRE_2D_VIEWPORT_TYPES;
      } else {
        const mprViewTypes = this.#getAvailableMPRViewTypes();

        if (mprViewTypes == null) {
          return DRE_2D_VIEWPORT_TYPES;
        }

        this.#availableViewTypes = [
          ...DRE_2D_VIEWPORT_TYPES,
          ...mprViewTypes,
          ...DRE_3D_VIEWPORT_TYPES,
        ];
      }
    }

    return this.#availableViewTypes;
  }

  /**
   * For a given point ({position}) in world space, find the closest slice for the
   * given {viewType} of this series.
   */
  getSliceForWorldPosition(
    position: ?vec3,
    viewType: DreAllViewportTypes,
    clamp: boolean = true
  ): ?number {
    if (position == null) return;

    const [minSlices, maxSlices] = this._getViewTypeBounds(viewType);

    let slice;
    if (viewType === 'TWO_D_DRE') {
      if (this.is3Dable()) {
        // The default view type uses K slicing in index space, so we need to convert
        // from world space to index space. Since this is '3Dable', meaning all image
        // planes are parallel, we can use the main `worldToIndex` transform.
        const indexSpacePosition = this.worldToIndex([...position]);
        if (this.absoluteScroll) {
          slice = Math.abs(Math.round(indexSpacePosition[2]));
        } else {
          slice = Math.round(indexSpacePosition[2]);
        }
      } else {
        // Image has more than one distinct direction, so dynamically calculate
        // based on all tag data instead.
        let minSlice = -1;
        let min = Number.POSITIVE_INFINITY;

        for (let i = 0; i < this.stackSize; i++) {
          const smid = this.frameSmidsMap[i];
          const tag = this.frameTags.find((t) => t.smid === smid);
          const plane = vtkPlane.newInstance();
          const slicePlane = this.getSlicePlaneAtViewIndex(viewType, i);

          if (slicePlane != null && tag != null) {
            plane.setNormal(...slicePlane.sliceNormal);
            plane.setOrigin(...slicePlane.slicePosition);
            const val = plane.distanceToPlane(
              position,
              slicePlane.slicePosition,
              slicePlane.sliceNormal
            );
            if (val < min && (val < tag.spacing[2] || clamp)) {
              min = val;
              minSlice = i;
            }
          }
        }
        if (minSlice !== -1) {
          slice = minSlice;
        }
      }
    } else {
      const index = this.getXYZSlicingModeIndex(viewType);
      slice = Math.round(position[index]);
    }
    if (slice == null) return null;

    const clampedSlice = Math.round(clampValue(slice, minSlices, maxSlices));

    if (clamp) {
      return clampedSlice;
    } else if (slice === clampedSlice) {
      return slice;
    }

    return null;
  }

  /**
   * For a given {distanceVector}, representing the distance we want to move from the
   * {manualSyncPosition} for the given {viewType}, find the closest slice to position
   * vector produced from adding the {distanceVector} to the {manualSyncPosition}.
   */
  getSliceForManualScrollSync(distanceVector: vec3, viewType: DreAllViewportTypes): ?number {
    // even if the series isn't active in this window, the state is synchronized with the one from the
    // window where it is active, so we just search for one that has the sync position defined already
    const activeStateEntry = Array.from(this.#viewportsStateMap.entries()).find(
      ([stateId, state]) => stateId.includes(viewType) && state.manualSyncPosition != null
    );
    const viewportId = activeStateEntry?.[1].viewportId;
    if (viewportId == null) return;
    const manualSyncPosition = activeStateEntry?.[1].manualSyncPosition;

    if (manualSyncPosition == null) return;

    if (distanceVector[0] === 0 && distanceVector[1] === 0 && distanceVector[2] === 0) {
      return this.getSliceForWorldPosition(manualSyncPosition, viewType);
    } else {
      // We add the vector to the intitial synced position,
      // giving us the new position of our current viewport
      const newPosition = add(manualSyncPosition, distanceVector, [0, 0, 0]);
      return this.getSliceForWorldPosition(newPosition, viewType);
    }
  }

  /**
   * TODO: Document method
   */
  calculateIntersection(
    viewType: DreAllViewportTypes,
    viewportId: string,
    corners: Array<vec3>,
    sourceSeriesProvider: BaseImagingProvider<Stack>,
    sourceViewType: DreAllViewportTypes,
    sourceActiveSlice: number
  ): [?vec3, ?vec3] {
    const activeSlice = this.getActiveSlice(viewType, viewportId);
    const line = this.#computeLine(
      viewType,
      viewportId,
      activeSlice,
      sourceSeriesProvider,
      sourceViewType,
      sourceActiveSlice
    );
    const image = this.vtkImage;

    if (image == null || line == null) return [null, null];

    return this.#findIntersection({ viewType, line, corners, activeSlice });
  }

  addVtkSlicer(
    viewType: DreAllViewportTypes,
    viewportId: string,
    vtkSlicer: typeof SliceRepresentation
  ) {
    const ijkMode = vtkSlicer.getMapper()?.getClosestIJKAxis().ijkMode;
    const slicingModeIndex: $Values<SlicingMode> = ijkMode != null && ijkMode < 3 ? ijkMode : 2;
    this.#setViewportState(viewType, viewportId, (state) => ({
      ...state,
      vtkSlicers: [...state.vtkSlicers, vtkSlicer],
      slicingModeIndex,
    }));
  }

  removeVtkSlicer(
    viewType: DreAllViewportTypes,
    viewportId: string,
    vtkSlicer: ?typeof SliceRepresentation
  ) {
    this.#setViewportState(viewType, viewportId, (state) => ({
      ...state,
      vtkSlicers: state.vtkSlicers?.filter((slicer) => slicer !== vtkSlicer),
    }));
  }

  calculateLayerProviders(stackProviders: Map<string, BaseImagingProvider<Stack>>): void {
    // Not implemented for single-layer providers
  }

  getLayeredVTKImage(): typeof vtkImageData {
    throw new Error('Not implemented');
  }

  /** Returns the original ImagingProvider rendering the stackSmid provided from the list of
   * referenced providers */
  // eslint-disable-next-line no-use-before-define
  getRefProviderByStackSmid(stackSmid: string): ?BaseImagingProvider<Stack> {
    // Not implemented for single-layer providers
    return;
  }

  seriesAreHomogenous(): boolean {
    throw new Error('Not implemented');
  }

  /**
   * Iterate through all other available stacks in the given {stackProviders} map
   * and identify view types of other stacks to which a view type of this stack can be linked.
   *
   * A view of a stack can be automatically linked to a view of a different stack
   * if all of the following are true:
   * - The stacks contain frames from a single series
   * - The series for the stacks belong to the same study
   * - A slice in one series can be mapped (via {getSlicePlaneAtViewIndex}) to another series
   * - The slices of each series are parallel (with a given tolerance)
   */
  calculateLinkedStacks(stackProviders: Map<string, BaseImagingProvider<Stack>>) {
    // If this stack has already been linked we don't need to recalculate again.
    if (this.#isLinked()) return;

    const imageRegistrations = [...stackProviders.values()].flatMap(
      (provider) => provider.imageRegistrations
    );

    for (const [stackSmid, targetProvider] of stackProviders.entries()) {
      // No calculation needed for this stack
      if (this.stack.smid === stackSmid) continue;

      for (const viewType of this.getAvailableViewTypes()) {
        const slicePlane = this.getSlicePlaneAtViewIndex(viewType, 0);
        if (slicePlane == null) break;

        const { sliceNormal, slicePosition } = slicePlane;
        for (const targetViewType of targetProvider.getAvailableViewTypes()) {
          const targetLinkedSlice = targetProvider.getSliceForWorldPosition(
            slicePosition,
            viewType
          );
          if (targetLinkedSlice == null) break;

          const targetSlicePlane = targetProvider.getSlicePlaneAtViewIndex(
            targetViewType,
            targetLinkedSlice
          );

          if (targetSlicePlane == null) break;

          const { sliceNormal: targetSliceNormal } = targetSlicePlane;
          const isParallelWithTargetSlice = areNormalsParallelWithTolerance(
            targetSliceNormal,
            sliceNormal,
            AUTOMATIC_LINKED_SCROLL_TOLERANCE_RADIANS
          );

          if (isParallelWithTargetSlice) {
            const sameStudyAndFrameOfReference =
              this.study.smid === targetProvider.study.smid &&
              this.getFrameOfReference() === targetProvider.getFrameOfReference() &&
              this.getFrameOfReference() != null;

            const thisFrameOfReference = this.getFrameOfReference();
            const targetFrameOfReference = targetProvider.getFrameOfReference();

            // exclude non-homogenous series from image registration process as different frames
            // in the stack could have different frames of reference
            // we are unlikely to ever have registrations for non-homogenous stacks, but this
            // guarantees we won't apply a transform from a wrong frame of reference
            const seriesAreHomogenous =
              this.seriesAreHomogenous() && targetProvider.seriesAreHomogenous();

            const imageRegistrationGroupAB = seriesAreHomogenous
              ? imageRegistrations.find((imageRegistrationGroup) => {
                  return (
                    imageRegistrationGroup.fixedFrameOfReferenceUid === thisFrameOfReference &&
                    imageRegistrationGroup.movingFrameOfReferenceUid === targetFrameOfReference
                  );
                })
              : null;
            const imageRegistrationGroupBA = seriesAreHomogenous
              ? imageRegistrations.find((imageRegistration) => {
                  return (
                    imageRegistration.fixedFrameOfReferenceUid === targetFrameOfReference &&
                    imageRegistration.movingFrameOfReferenceUid === thisFrameOfReference
                  );
                })
              : null;

            const transformMatrix = pickNewestImageRegistrationMatrix(
              imageRegistrationGroupAB,
              imageRegistrationGroupBA
            );

            const transformMatrixInverted =
              transformMatrix != null ? invertTransformMatrix(transformMatrix) : null;

            const aPositionToBSlice = (aPosition: ?vec3, aManualScrollDistance: ?vec3) => {
              if (sameStudyAndFrameOfReference) {
                // Automatic linked scrolling resolution
                return targetProvider.getSliceForWorldPosition(aPosition, targetViewType);
              } else if (
                transformMatrixInverted != null &&
                aPosition != null &&
                aManualScrollDistance == null // use manual if available
              ) {
                // image registration
                const targetPosition = applyTransformMatrix(aPosition, transformMatrixInverted);
                const targetSlice = targetProvider.getSliceForWorldPosition(
                  targetPosition,
                  targetViewType
                );
                logger.info(
                  `IR AB(invert) ${targetSlice ?? '?'} (${
                    targetProvider.getSliceForWorldPosition(aPosition, targetViewType) ?? '?'
                  })`
                );
                return targetSlice;
              } else {
                // Manual linked scroll resolution
                if (aManualScrollDistance == null) return;

                return targetProvider.getSliceForManualScrollSync(
                  aManualScrollDistance,
                  targetViewType
                );
              }
            };
            const bPositionToASlice = (bPosition: ?vec3, bManualScrollDistance: ?vec3) => {
              if (sameStudyAndFrameOfReference) {
                // Automatic linked scrolling resolution
                return this.getSliceForWorldPosition(bPosition, viewType);
              } else if (
                transformMatrix != null &&
                bPosition != null &&
                bManualScrollDistance == null // use manual if available
              ) {
                const targetPosition = applyTransformMatrix(bPosition, transformMatrix);
                const targetSlice = this.getSliceForWorldPosition(targetPosition, targetViewType);
                logger.info(
                  `IR BA ${targetSlice ?? '?'} (${
                    targetProvider.getSliceForWorldPosition(bPosition, targetViewType) ?? '?'
                  })`
                );
                return targetSlice;
              } else {
                // Manual linked scroll resolution
                if (bManualScrollDistance == null) return;

                return this.getSliceForManualScrollSync(bManualScrollDistance, targetViewType);
              }
            };

            this.#linkManager.linkStackViews({
              viewTypeA: viewType,
              stackProviderA: this,
              viewTypeB: targetViewType,
              stackProviderB: targetProvider,
              aPositionToBSlice,
              bPositionToASlice,
              type:
                sameStudyAndFrameOfReference || transformMatrix != null ? 'automatic' : 'manual',
            });
          }
        }
      }
    }
  }

  getLinks(
    linkSmid: string,
    viewType: DreAllViewportTypes
  ): Array<{
    //eslint-disable-next-line no-use-before-define
    targetProvider: BaseImagingProvider<Stack>,
    targetViewType: DreAllViewportTypes,
    positionToSliceTransform: PositionToSliceTransform,
  }> {
    return this.#linkManager.getLinksForView(linkSmid, viewType);
  }

  getLinkManagerMode(): TScrollLinkMode {
    return this.#linkManager.getScrollLinkMode();
  }

  syncSliceFromBroadcast(viewType: DreAllViewportTypes, viewportId: string, slice: number) {
    this._updateVtkSlice(viewType, viewportId, slice, false);

    this.#renderManager.queueUpdate(this, createActiveSliceChangedEmitterEvent(viewType));
    this.#renderManager.throttledRender(viewType);
  }

  getNumberOfColorChannels(frameIndex: number): number {
    throw new Error('Not implemented');
  }

  /**
   * using viewportsConfigurations as a filter, the provider looks through its internal state
   * for a loaded viewport state we can use for a stable active slice
   * originally used by ViewerContext to pick a focus slice for deprioritizing unlinked stacks
   */
  getFirstHangedViewportSlice(viewportsConfigurations: ViewportsConfigurations): number | null {
    for (const state of this.#viewportsStateMap.values()) {
      const viewportConfig = viewportsConfigurations[state.viewportId];
      // only viewportsConfigurations is kept up to date with what is actually on display,
      // so we have to verify the state with the config
      if (
        viewportConfig != null &&
        viewportConfig.stack?.smid === this.stack.smid &&
        viewportConfig.viewType === state.viewType
      ) {
        return state.activeSlice;
      }
    }
    return null;
  }

  /**
   * Implemented in extending classes.
   *
   * Contains logic for updating the load priority for the pixel data for this series.
   */
  updateLoadPriority(priorityProps: { focus: number, priority: number }) {}

  onImageChange(
    viewportId: string,
    viewType: DreAllViewportTypes,
    callback: (vtkImage: typeof vtkImageData | null) => void
  ): string {
    const eventListenerId = nanoid();
    this.#imageChangeListeners.set(eventListenerId, { viewportId, viewType, callback });
    return eventListenerId;
  }

  offImageChange(eventListenerId: string) {
    this.#imageChangeListeners.delete(eventListenerId);
  }

  triggerImageChangeCallbacks: (slice: number) => mixed = (slice: number) => {
    const image = this.is3Dable() ? this.getImage() : this.getImage(this.frameSmidsMap[slice]);
    Array.from(this.#imageChangeListeners.values()).forEach((listener) => {
      const { viewportId, viewType, callback } = listener;
      const isActiveSlice = this.getActiveSlice(viewType, viewportId) === slice;
      if (viewType !== ViewTypeValues.TwoDDre || isActiveSlice) {
        callback(image);
      }
    });
  };

  getImage(frameSmid: ?string): typeof vtkImageData | null {
    throw new Error('Not implemented.');
  }

  getFramePixels(frameSmid: string): SupportedTextureTypes | null {
    throw new Error('Not implemented.');
  }

  // mark a slice as viewed and return a set of series smids that are
  // sufficiently reviewed and associated with the active slice
  markSliceViewed(viewType: DreAllViewportTypes, slice: number): Set<string> {
    throw new Error('Not implemented.');
  }

  /******************************************************************
   * Memory Management
   ******************************************************************/

  unloadFrameFromMemory(frameIndex: number): void {
    throw new Error('Not implemented.');
  }

  loadFrameIntoMemory(frameIndex: number, options?: { unloadOthers: boolean }): void {
    throw new Error('Not implemented.');
  }

  unloadAllFrames(): void {
    throw new Error('Not implemented.');
  }

  reloadAllFramesIntoMemory(): void {
    throw new Error('Not implemented.');
  }

  isFrameLoaded(frameSmid: string): boolean {
    throw new Error('Not implemented.');
  }

  // return the number of frames in active memory
  getFramesLoaded(): number {
    throw new Error('Not implemented.');
  }

  isLayeredStack(): boolean {
    return false;
  }

  /******************************************************************
   * Protected API - only called from within this file, either globally
   *                 from the broadcast-channel or direct provider-to-provider
   ******************************************************************/

  /**
   * _PROTECTED_ - Only called from an imaging provider class
   *
   * Implemented in extending classes.
   *
   * Contains logic for loading the pixel data for this series.
   */
  async _loadStack(initialSlice: number, priority: number): Promise<void> {
    throw new Error('Not implemented.');
  }

  /**
   * _PROTECTED_ - Only called from an imaging provider class
   *
   * Updates the internal active slice state for the given {viewType}
   * and updates the VTK slicer's current slice.
   */
  _updateSliceAndLinks(
    viewType: DreAllViewportTypes,
    viewportId: string,
    slice: number,
    slicePosition: ?vec3,
    manualScrollDistance: ?vec3
  ) {
    this.#renderManager.queueUpdate(this, createActiveSliceChangedEmitterEvent(viewType));

    const isScrollLinkingEnabled = this.#linkManager.isScrollLinkingEnabled();

    if (isScrollLinkingEnabled) {
      this._updateVtkSliceForAllViewports(viewType, slice);

      if (slicePosition != null) {
        // Update the slice on all linked stacks
        const linkedStacks = this.#linkManager.getLinksForView(this.stack.smid, viewType);
        linkedStacks.forEach(({ targetProvider, targetViewType, positionToSliceTransform }) => {
          const updatedSlice = positionToSliceTransform(slicePosition, manualScrollDistance);
          if (updatedSlice != null) {
            targetProvider._updateVtkSliceForAllViewports(targetViewType, updatedSlice);
            if (targetProvider.status === 'loading') {
              targetProvider.updateLoadPriority({
                focus: slice,
                priority: LOADING_PRIORITIES.LINKED,
              });
            }
            this.#renderManager.queueUpdate(
              targetProvider,
              createActiveSliceChangedEmitterEvent(targetViewType)
            );
          }
        });
      }
    } else {
      this._updateVtkSlice(viewType, viewportId, slice);
    }
    if (this.status === 'loading') {
      this.updateLoadPriority({ focus: slice, priority: LOADING_PRIORITIES.ACTIVE });
    }
    this.#renderManager.throttledRender(viewType);
  }

  _updateVtkSliceForAllViewports(viewType: DreAllViewportTypes, slice: number) {
    Array.from(this.#viewportsStateMap.values())
      .filter((state) => state.viewType === viewType)
      .forEach((state) => {
        this._updateVtkSlice(viewType, state.viewportId, slice);
      });
  }

  _setDroppedStack(dropped: boolean) {
    this.#isDroppedStack = dropped;
  }

  _updateVtkSlice(
    viewType: DreAllViewportTypes,
    viewportId: string,
    slice: number,
    broadcast?: boolean = true
  ) {
    const sliceName = SLICE_AXIS[viewType];
    const frameSmid = this.frameSmidsMap[slice];
    const setter = `set${sliceName[0].toUpperCase()}${sliceName.slice(1)}`;
    this.#setViewportState(viewType, viewportId, (state) => ({
      ...state,
      activeSlice: slice,
    }));

    // Doing a renderView here runs every slice change, faster than 60fps,
    // so we render the view in a rafThrottle instead.
    // if we are using a non3dable stack, we will always be displaying slice 0
    if (this.is3Dable()) {
      this.#getViewportState(viewType, viewportId).vtkSlicers?.forEach((slicer) => {
        slicer.getMapper()?.[setter]?.(slice);
      });
    } else {
      // non 3dable frames may have variable pixel spacing
      // to display and measure them properly we need to set the spacing
      const activeSliceTags = this.getFrameTagsForViewIndex(viewType, slice);
      if (activeSliceTags == null) return;
      this.vtkImage.setSpacing(activeSliceTags.spacing);
    }

    if (broadcast) {
      this.synchronizationBroadcaster.queueMessage({
        action: 'sliceChange',
        details: {
          viewType,
          viewportId,
          stackSmid: this.stack.smid,
          slice,
          frameSmid,
        },
      });
    }
  }

  _calculateManualScrollDistance(viewType: DreAllViewportTypes, viewportId: string): ?vec3 {
    const slicePlane = this.getActiveSlicePlane(viewType, viewportId);
    const syncPosition = this.getManualSyncPosition(viewType, viewportId);
    if (syncPosition == null || slicePlane == null) return;

    // Calculate the distance between the initial synced position and the current position.
    // We can do this by subtracting the two positions (new - old)
    return [
      slicePlane.slicePosition[0] - syncPosition[0],
      slicePlane.slicePosition[1] - syncPosition[1],
      slicePlane.slicePosition[2] - syncPosition[2],
    ];
  }

  _getViewTypeBounds(viewType: DreAllViewportTypes): [number, number] {
    let minSlices = 0;
    let maxSlices = 0;

    if (!this.is3Dable()) {
      minSlices = 0;
      maxSlices = this.frameTags.length - 1;
    } else {
      const image = this.vtkImage;
      const bounds = image.getBounds();
      const extent = image.getExtent();
      switch (viewType) {
        case 'TWO_D_DRE':
          minSlices = extent[4];
          maxSlices = extent[5];
          break;
        case 'AXIAL_DRE':
          minSlices = Math.round(bounds[4]);
          maxSlices = Math.round(bounds[5]);
          break;
        case 'CORONAL_DRE':
          minSlices = Math.round(bounds[2]);
          maxSlices = Math.round(bounds[3]);
          break;
        case 'SAGITTAL_DRE':
          minSlices = Math.round(bounds[0]);
          maxSlices = Math.round(bounds[1]);
          break;
        default:
          minSlices = Math.round(extent[4]);
          maxSlices = Math.round(extent[5]);
          break;
      }
    }

    return [minSlices, maxSlices];
  }

  /******************************************************************
   * Private API
   ******************************************************************/

  #serializeViewportStateKey(viewType: DreAllViewportTypes, viewportId: string): string {
    return `${viewType}:${viewportId}`;
  }

  #deserializeViewportStateKey(key: string): { viewType: string, viewportId: string } {
    const [viewType, viewportId] = key.split(':');
    return { viewType, viewportId };
  }

  #getViewportState(viewType: DreAllViewportTypes, viewportId: string): ViewportState {
    return (
      this.#viewportsStateMap.get(this.#serializeViewportStateKey(viewType, viewportId)) ?? {
        ...DEFAULT_VIEWPORT_STATE,
        viewportId,
        viewType,
      }
    );
  }

  #getViewTypeState(
    viewType: DreAllViewportTypes
  ): ?$Diff<ViewportState, { activeSlice: mixed, viewportId: mixed }> {
    const viewTypeState = Array.from(this.#viewportsStateMap.values()).find(
      (viewportState) => viewportState.viewType === viewType
    );
    if (viewTypeState == null) {
      return {
        ...DEFAULT_VIEWTYPE_STATE,
        viewType,
      };
    }
    const { activeSlice, viewportId, ...viewTypeStateWithoutActiveSlice } = viewTypeState;
    return viewTypeStateWithoutActiveSlice;
  }

  getSlicerForViewtype(
    viewType: DreAllViewportTypes,
    layer: number
  ): ?ViewportState['vtkSlicers'][0] {
    const viewTypeState = Array.from(this.#viewportsStateMap.values()).find(
      (viewportState) => viewportState.viewType === viewType
    );
    const slicers = viewTypeState?.vtkSlicers;

    if (slicers != null && slicers[layer] != null) {
      return slicers[layer];
    }

    return null;
  }

  #setViewportState(
    viewType: DreAllViewportTypes,
    viewportId: string,
    updater: SetStateAction<ViewportState>
  ): void {
    if (typeof updater === 'function') {
      const newState = updater(this.#getViewportState(viewType, viewportId));
      this.#viewportsStateMap.set(this.#serializeViewportStateKey(viewType, viewportId), newState);
    } else {
      this.#viewportsStateMap.set(this.#serializeViewportStateKey(viewType, viewportId), updater);
    }
  }

  /**
   * Returns the "available" MPR view types by filtering out the
   * MPR view that is parallel to the acquisition view.
   *
   * Example: For a chest CT that displays on the axial plane by default
   *          (the acquisition view), the view types returned will be:
   *          ['TWO_D_DRE', 'CORONAL_DRE', 'SAGITTAL_DRE']
   */
  #getAvailableMPRViewTypes(): ?Array<DreMprViewportTypes> {
    const slicePlane = this.getSlicePlaneAtViewIndex('TWO_D_DRE', 0);
    if (slicePlane == null) return;

    const { sliceNormal, slicePosition } = slicePlane;
    for (const mprViewType of DRE_MPR_VIEWPORT_TYPES) {
      const mprSlice = this.getSliceForWorldPosition(slicePosition, mprViewType);
      if (mprSlice == null) break;

      const mprSlicePlane = this.getSlicePlaneAtViewIndex(mprViewType, mprSlice);
      if (mprSlicePlane == null) break;

      const { sliceNormal: mprSliceNormal } = mprSlicePlane;
      const isParallelWithMPR = areNormalsParallelWithTolerance(
        mprSliceNormal,
        sliceNormal,
        AUTOMATIC_LINKED_SCROLL_TOLERANCE_RADIANS
      );

      if (isParallelWithMPR) {
        return DRE_MPR_VIEWPORT_TYPES.filter((viewType) => viewType !== mprViewType);
      }
    }
  }

  /**
   * Determines if any of the available view types for this series
   * have been linked to any view type of any other series.
   */
  #isLinked(): boolean {
    const availableViewTypes = this.getAvailableViewTypes();
    return availableViewTypes.some((viewType) => {
      return this.#linkManager.hasLinks(this.stack.smid, viewType);
    });
  }

  // TODO: document method - taken from LocalizationLine component
  #computeLine(
    viewType: DreAllViewportTypes,
    viewportId: string,
    slice: number,
    sourceSeriesProvider: BaseImagingProvider<Stack>,
    sourceViewType: DreAllViewportTypes,
    sourceActiveSlice: number
  ): ?IRet {
    const slicePlane = this.getSlicePlaneAtViewIndex(viewType, slice);
    const sourceSlicePlane = sourceSeriesProvider.getSlicePlaneAtViewIndex(
      sourceViewType,
      sourceActiveSlice
    );

    if (sourceSlicePlane == null || slicePlane == null) return;

    const { slicePosition, sliceNormal } = slicePlane;
    const { slicePosition: sourceSlicePosition, sliceNormal: sourceSliceNormal } = sourceSlicePlane;

    const isParallel = areNormalsParallelWithTolerance(
      sliceNormal,
      sourceSliceNormal,
      AUTOMATIC_LINKED_SCROLL_TOLERANCE_RADIANS
    );

    if (isParallel) {
      // if we are parallel to the guide viewport, just return no line
      return null;
    }

    // find the intersection between the planes defined by the slices
    const sourceVtkSlicePlane = vtkPlane.newInstance();
    sourceVtkSlicePlane.setNormal(...sourceSliceNormal);
    sourceVtkSlicePlane.setOrigin(...sourceSlicePosition);

    const line = sourceVtkSlicePlane.intersectWithPlane(slicePosition, sliceNormal);

    if (line?.intersection !== true) return null;

    return line;
  }

  // TODO: document method - taken from LocalizationLine component
  // transform all our coords to 2D and check for the intersection
  // of the infinite lines defined by the intersection and each
  // side of the slice
  #findIntersection({
    line,
    corners,
    viewType,
    activeSlice,
  }: {
    line: IRet,
    corners: Array<vec3>,
    activeSlice: number,
    viewType: DreAllViewportTypes,
  }): [?vec3, ?vec3] {
    const tags = this.getFrameTagsForViewIndex(viewType, activeSlice);

    // The orientationMapping object is used to map from index space to rows / cols of the current active viewtype
    const orientationMapping = {
      TWO_D_DRE: [0, 1],
      THREE_D_DRE: [0, 1],
      THREE_D_MIP: [0, 1],
      AXIAL_DRE: [this.lpsDirections.Coronal, this.lpsDirections.Sagittal],
      CORONAL_DRE: [this.lpsDirections.Axial, this.lpsDirections.Sagittal],
      SAGITTAL_DRE: [this.lpsDirections.Coronal, this.lpsDirections.Axial],
    };

    // indexes is our mapping from IJK space to the current viewtype
    const indexesForCurrentViewtype = orientationMapping[viewType];
    const firstIndexPoint = this.worldToIndex(line.l0, tags);
    const secondIndexPoint = this.worldToIndex(line.l1, tags);
    const [x0, y0] = [
      firstIndexPoint[indexesForCurrentViewtype[0]],
      firstIndexPoint[indexesForCurrentViewtype[1]],
    ];
    const [x1, y1] = [
      secondIndexPoint[indexesForCurrentViewtype[0]],
      secondIndexPoint[indexesForCurrentViewtype[1]],
    ];

    const checkIntersection = (x2: number, y2: number, x3: number, y3: number) => {
      return check2DLineIntersection(
        [
          [x0, y0],
          [x1, y1],
        ],
        [
          [x2, y2],
          [x3, y3],
        ]
      );
    };

    const indexSpaceCorners = corners.map((coord) => this.worldToIndex(coord, tags) ?? [0, 0]);

    const topIntersectPoint = checkIntersection(
      indexSpaceCorners[0][indexesForCurrentViewtype[0]],
      indexSpaceCorners[0][indexesForCurrentViewtype[1]],
      indexSpaceCorners[1][indexesForCurrentViewtype[0]],
      indexSpaceCorners[1][indexesForCurrentViewtype[1]]
    );
    const rightIntersectPoint = checkIntersection(
      indexSpaceCorners[1][indexesForCurrentViewtype[0]],
      indexSpaceCorners[1][indexesForCurrentViewtype[1]],
      indexSpaceCorners[2][indexesForCurrentViewtype[0]],
      indexSpaceCorners[2][indexesForCurrentViewtype[1]]
    );
    const bottomIntersectPoint = checkIntersection(
      indexSpaceCorners[2][indexesForCurrentViewtype[0]],
      indexSpaceCorners[2][indexesForCurrentViewtype[1]],
      indexSpaceCorners[3][indexesForCurrentViewtype[0]],
      indexSpaceCorners[3][indexesForCurrentViewtype[1]]
    );
    const leftIntersectPoint = checkIntersection(
      indexSpaceCorners[0][indexesForCurrentViewtype[0]],
      indexSpaceCorners[0][indexesForCurrentViewtype[1]],
      indexSpaceCorners[3][indexesForCurrentViewtype[0]],
      indexSpaceCorners[3][indexesForCurrentViewtype[1]]
    );

    const intersections = [
      topIntersectPoint,
      rightIntersectPoint,
      bottomIntersectPoint,
      leftIntersectPoint,
    ]
      .filter((intersection) => intersection.onLine1 || intersection.onLine2)
      .map((intersection) => {
        /*
        intersection has the properties:
        - x: the x coordinate of the intersection
        - y: the y coordinate of the intersection

        these are only X and Y relative to the current plane. If we are not in
        the acquisition view, we need to convert these to the actual i j k coordinates of the volume.

        Additionally, if the viewType is TWO_D_DRE, the activeSlice is the K coordinate of the current slice
        Otherwise, activeSlice is an x, y, or z coordinate. We set our K index to the minimum value
        and then after converting to world space, we set our xyz slicing index to be the active slice

        */

        if (viewType === 'TWO_D_DRE') {
          return this.indexToWorld([
            intersection.x,
            intersection.y,
            this.is3Dable() ? activeSlice : 0,
          ]);
        } else {
          const majorIJKAxis = [0, 1, 2].find(
            (index) => !indexesForCurrentViewtype.includes(index)
          );
          if (majorIJKAxis == null) return null;

          const XYZAxis = this.getXYZSlicingModeIndex(viewType);
          const indexPoint = [0, 0, 0];
          indexPoint[indexesForCurrentViewtype[0]] = intersection.x;
          indexPoint[indexesForCurrentViewtype[1]] = intersection.y;

          // bounds and extent look like this:
          // [xmin, xmax, ymin, ymax, zmin, zmax]
          const extent = this.vtkImage.getSpatialExtent();
          // so given axis [x, y, z ] indexed as [0, 1, 2] we can get the minimum for that axis with
          // 2 * axisindex and the maximum with 2 * axisIndex + 1

          indexPoint[majorIJKAxis] = (extent[2 * majorIJKAxis] + extent[2 * majorIJKAxis + 1]) / 2;
          const worldPoint = this.indexToWorld(indexPoint);
          if (XYZAxis === 0 || XYZAxis === 1 || XYZAxis === 2) {
            worldPoint[XYZAxis] = activeSlice;
          }
          return worldPoint;
        }
      });

    if (intersections[1] == null) return [null, null];

    return [intersections[0], intersections[1]];
  }

  getFrameOfReference(): ?string {
    return this.#frameOfReferenceUID;
  }

  getSeries(): ?Series {
    return this.#series;
  }
}
// TEMP(andrew.walton): Copy Will's progressive loading code to generate a "mock" / "synthetic"
// VTK image based solely on tag data, to allow us to use the VTK image in various computations
// before the series is loaded. This should be unnecessary once we are able to run all of our
// calculations using solely tag data and no VTK image.
export function generateMockVtkImage({
  origin,
  spacing,
  direction,
  dimensions,
  extent,
}: $ReadOnly<ImageParams>): typeof vtkImageData {
  const mockImage = vtkImageData.newInstance({ spacing, origin, direction, extent });
  mockImage.setDimensions(...dimensions);
  return mockImage;
}

export function pickNewestImageRegistrationMatrix(
  imageRegistrationGroupAB: ?ImageRegistration,
  imageRegistrationGroupBA: ?ImageRegistration
): ?TransformMatrix {
  const matrixAB = imageRegistrationGroupAB?.transformMatrix;
  const matrixBA = imageRegistrationGroupBA?.transformMatrix;
  if (matrixAB != null && matrixBA == null) {
    logger.info('using ImageRegistrationGroup A->B', imageRegistrationGroupAB);
    return invertTransformMatrix(matrixAB);
  } else if (matrixBA != null && matrixAB == null) {
    logger.info('using ImageRegistrationGroup B->A', imageRegistrationGroupBA);
    return matrixBA;
  } else if (
    matrixBA != null &&
    matrixAB != null &&
    imageRegistrationGroupAB != null &&
    imageRegistrationGroupBA != null
  ) {
    const ABNewer =
      new Date(imageRegistrationGroupAB.created) > new Date(imageRegistrationGroupBA.created);
    logger.info(
      `using newer ImageRegistrationGroup ${ABNewer ? 'A->B' : 'B->A'}`,
      ABNewer ? imageRegistrationGroupAB : imageRegistrationGroupBA
    );
    logger.info(
      `NOT USED ImageRegistrationGroup ${ABNewer ? 'B->A' : 'A->B'}`,
      ABNewer ? imageRegistrationGroupBA : imageRegistrationGroupAB
    );
    return ABNewer ? invertTransformMatrix(matrixAB) : matrixBA;
  }
  return null;
}
